@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-align: left;
  margin: auto;
}
.Inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

$marginHome: 16px;
$marginProj: 16px;
$lightGreyText: #FFFFFF;
$darkGreyText: #E0E0E0;
$thirdColorText: #757575;
$fontsizemin: 11px;
$fontSize-1: 14px;
$fontSizeTyp: 16px;
$fontSize1: 22px;
$fontSize2: 22px;
$fontSize3: 30px;
$fontSize4: 36px;
$fontSizeX: 48px;
$fontSize5: 64px;
$fontSize6: calc(#{$fontSize5});


$mobilepadding: 64px;
$projpadding: 120px;
$typLetterSpacing: .005em;
$titleLetterSpacing: -.02em;
$typlineheight: 1.5;
$calclineheight: calc(#{$fontSize2} * #{$typlineheight});

$navLarge: 100px;
$font:  "Inter", sans-serif;
$font2:  "Inter", sans-serif;
$fontWeightMin: 225;
$fontWeightReduced: 400;
$fontWeightTyp: 500;
$cream: hsl(50, 75%, 95%);
$typ-lighttext-color: white;

$tree-bg-color:       #171717;
$tree-bg-color-hover: #222;
$tree-border-color:   #666;
$tree-text-color:     #ccc;


$lrtColor:hsl(36, 55%, 16%);
$lrtDesatColor:hsl(36, 15%, 16%);
$lrt2Color:hsl(36, 48%, 85%);
$lrtBackgroundColor: hsl(36, 15%, 85%);
$lrtBackgroundAdjust: mix($lrtBackgroundColor, $cream,50%);
$lrtITColor: lighten($lrtColor,12%);
$lrtLightTextColor:desaturate($lrtITColor,14%);
$lrtSurfaceColor:lighten($lrtDesatColor,79%);
$lrtTextColor:lighten($lrtColor,2%);

$androidColor:hsl(346, 20%, 27%);
$androidDesatColor:hsl(346, 15%, 16%);
$android2Color:hsl(346, 48%, 85%);
$androidBackgroundColor: hsl(346, 8%, 5%);
$androidBackgroundAdjust: mix($androidBackgroundColor, $cream,50%);
$androidITColor: lighten($androidColor,12%);
$androidLightTextColor:desaturate($androidITColor,14%);
$androidSurfaceColor:lighten($androidDesatColor,79%);
$androidTextColor:lighten($androidColor,15%);


$feltColor:hsl(339, 50%, 21%);
$felt2Color:hsl(339, 68%, 86%);
$feltBackgroundColor: hsl( 339, 24%, 88%);
$feltBackgroundAdjust: mix($feltBackgroundColor, $cream,50%);
$feltITColor: lighten($feltColor,12%);
$feltLightTextColor:desaturate($feltITColor,24%);
$feltLightTextColor2:hsl(184, 90%, 40%);
$feltSurfaceColor:lighten($feltColor,76%);
$feltTextColor:lighten($feltColor, 22%);

$shoeColor:hsl(330, 40%, 21%);
$shoe2Color:hsl(1, 78%, 86%);
$shoeBackgroundColor: hsl( 300, 15%, 90%);
$shoeBackgroundAdjust: mix($shoeBackgroundColor, $cream,50%);
$shoeITColor:lighten($shoeColor,18%);
$shoeLightTextColor:desaturate($shoeITColor,14%);
$shoeSurfaceColor:lighten($shoeColor,75%);
$shoeTextColor:lighten($shoeColor,18%);

$ebayColor:hsl(213, 40%, 15%);
$ebay2Color:hsl(213, 78%, 85%);
$ebayBackgroundColor: hsl(213, 20%, 90%);
$ebayBackgroundAdjust: hsl(213, 20%, 90%);
$ebayITColor:lighten($ebayColor,15%);
$ebayLightTextColor:desaturate($ebayITColor,14%);
$ebayLightTextColor2:adjust-hue($ebayColor,120);
$ebaySurfaceColor:lighten($ebayColor,80%);
$ebayTextColor: lighten($ebayColor,12%);


$dentalColor:hsl(105, 50%, 21%);
$dentalDesatColor:hsl(105, 6%, 13%);
$dental2Color:hsl(105, 48%, 90%);
$dentalBackgroundColor: hsl( 105, 12%, 90%);
$dentalBackgroundAdjust: hsl( 105, 12%, 90%);
$dentalITColor:lighten($dentalColor,5%);
$dentalLightTextColor:desaturate($dentalITColor,20%);
$dentalLightTextColor2:hsl(225, 50%, 65%);
$dentalLightColor:lighten($dentalDesatColor,0%);
$dentalSurfaceColor:lighten($dentalDesatColor,82%);
$dentalTextColor:lighten($dentalColor,12%);

$gatoradeColor:hsl(278, 15%, 18%);
$gatorade2Color:hsl(278, 78%, 85%);
$gatoradeBackgroundColor: hsl(278, 12%, 90%);
$gatoradeBackgroundAdjust: mix($gatoradeBackgroundColor, $cream,50%);
$gatoradeITColor:lighten($gatoradeColor,20%);
$gatoradeLightTextColor:desaturate($gatoradeITColor,1%);
$gatoradeLightTextColor2:hsl(30, 100%, 50%);
$gatoradeSurfaceColor:lighten($gatoradeColor,78%);
$gatoradeTextColor:lighten($gatoradeColor,12%);


$hyperColor:hsl(340, 40%, 21%);
$hyper2Color:hsl(340, 48%, 86%);
$hyperBackgroundColor: hsl( 340, 25%, 88%);
$hyperBackgroundAdjust: mix($shoeBackgroundColor, $cream,50%);
$hyperITColor:lighten($hyperColor,18%);
$hyperLightTextColor:desaturate($hyperITColor,14%);
$hyperSurfaceColor:lighten($hyperColor,75%);
$hyperTextColor:lighten($hyperColor,18%);

$homeColor:hsl(222, 65%, 21%);
$home2Color:hsl(222, 48%, 90%);
$homeBackgroundColor: hsl(324, 25, 70);
$homeITColor:darken($homeBackgroundColor,48%);
$homeLightTextColor:desaturate($homeITColor,14%);
$homeLightTextColor2:hsl( 280, 0%, 45%);
$homeLightColor3:hsl( 280, 0%, 45%);
$homeTextColor:hsl(324, 57%, 35%);

html, body {
  background-color: $homeBackgroundColor !important;
}

.radius-8 {
  border-radius: 12px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.typ-surface-color {
  background: $home2Color !important;
}
.typ-light-color {
  color: $homeTextColor !important;
}
.typ-lighttext-color{
  color: $typ-lighttext-color !important;
}

.ebay-background-color {
  background-color: $ebayBackgroundColor !important;
}
.ebay-surface-color {
  background: $ebaySurfaceColor !important;
}
.ebay-border-color {
  border: 2px solid $ebayLightTextColor !important;
}
.ebay-light-color {
  color: $ebayLightTextColor !important;
}
.ebay-lighttext-color, .l-mobileDropdown a.ebay-lighttext-color {
  color: $ebayLightTextColor !important;
}
.ebay-lighttext-color2 {
  color: $ebayLightTextColor2 !important;
}
.ebay-dark-color {
  color: $ebayColor !important;
}
.ebay-fill-color {
  fill: $ebayLightTextColor !important;
}
.ebay-button-color {
color: $ebayLightTextColor !important;
}


.gatorade-background-color {
  background-color: $gatoradeBackgroundColor !important;
}
.gatorade-surface-color {
  background: $gatoradeSurfaceColor !important;
}
.gatorade-border-color {
  border: 2px solid $gatoradeLightTextColor !important;
}
.gatorade-light-color {
  color: $gatoradeLightTextColor !important;
}
.gatorade-lighttext-color, .l-mobileDropdown a.gatorade-lighttext-color {
  color: $gatoradeLightTextColor !important;
}
.gatorade-lighttext-color2 {
  color: $gatoradeLightTextColor2 !important;
}
.gatorade-dark-color {
  color: $gatoradeColor !important;
}
.gatorade-fill-color {
  fill: $gatoradeLightTextColor !important;
}
.st-gatorade {
  border: 2px solid $gatoradeBackgroundColor;
}

.shoe-background-color {
  background-color: $shoeBackgroundColor !important;
}
.shoe-surface-color {
  background: $shoeSurfaceColor !important;
}
.shoe-border-color {
  border: 2px solid $shoeLightTextColor!important;
}
.shoe-light-color {
  color: $shoeLightTextColor !important;
}
.shoe-lighttext-color, .l-mobileDropdown a.shoe-lighttext-color {
  color: $shoeLightTextColor !important;
}
.shoe-dark-color {
  color: $shoeColor !important;
}
.shoe-fill-color {
  fill: $shoeLightTextColor !important;
}

.hyper-background-color {
  background-color: $hyperBackgroundColor !important;
}
.hyper-surface-color {
  background: $hyperSurfaceColor !important;
}
.hyper-border-color {
  border: 2px solid $hyperLightTextColor !important;
}
.hyper-light-color {
  color: $hyperLightTextColor !important;
}
.hyper-lighttext-color, .l-mobileDropdown a.hyper-lighttext-color {
  color: $hyperLightTextColor !important;
}
.hyper-dark-color {
  color: $hyperColor !important;
}
.hyper-fill-color {
  fill: $hyperLightTextColor !important;
}
.st-hyper {
  border: 2px solid $hyperBackgroundColor;
}


.lrt-background-color {
  background-color: $lrtBackgroundColor !important;
}
.lrt-surface-color {
  background: $lrtSurfaceColor !important;
}
.lrt-surface2-color {
  background: $lrt2Color !important;
}
.lrt-border-color {
  border: 2px solid $lrtLightTextColor !important;
}
.lrt-light-color {
  color: $lrtLightTextColor !important;
}
.lrt-lighttext-color, .l-mobileDropdown a.lrt-lighttext-color {
  color: $lrtLightTextColor !important;
}
.lrt-dark-color {
  color: $lrtColor !important;
}
.lrt-fill-color {
  fill: $lrtLightTextColor !important;
}
.st-lrt {
  border: 2px solid $lrtBackgroundColor;
}

.dental-background-color {
  background-color: $dentalBackgroundColor !important;
}
.dental-surface-color {
  background: $dentalSurfaceColor !important;
}
.dental-border-color {
  border: 2px solid $dentalLightTextColor !important;
}
.dental-light-color {
  color:  $dentalLightTextColor  !important;
}
.dental-lighttext-color, .l-mobileDropdown a.dental-lighttext-color{
  color: $dentalLightTextColor !important;
}
.dental-lighttext-color2 {
  color: $dentalLightTextColor2 !important;
}
.dental-dark-color {
  color:  $dentalColor  !important;
}
.dental-fill-color {
  fill: $dentalLightTextColor !important;
}
.st-dental {
  border: 2px solid $dentalBackgroundColor;
}

.felt-background-color {
  background-color: $feltBackgroundColor !important;
}
.felt-surface-color {
  background: $feltSurfaceColor !important;
}
.felt-border-color {
  border: 2px solid $feltLightTextColor !important;
}
.felt-light-color {
  color: $feltLightTextColor !important;
}
.felt-lighttext-color, .l-mobileDropdown a.felt-lighttext-color {
  color: $feltLightTextColor !important;
}

.felt-lighttext-color2 {
  color: $feltLightTextColor2 !important;
}

.felt-dark-color {
  color: $feltColor !important;
}
.felt-fill-color {
  fill: $feltLightTextColor !important;
}
.st-felt {
  border: 2px solid $feltBackgroundColor;
}

.home-fill-color {
  fill: white !important;
}
.home-lighttext-color{
  color: $typ-lighttext-color !important;
}
.home-surface-color {
  background: $homeTextColor !important;
}
.home-background-color {
  background: $homeBackgroundColor !important;
}
.home-light-color {
  color: $homeTextColor !important;
}
.home-text-color {
  color: $homeTextColor !important;
}
.home-text-color2 {
  color: $homeLightTextColor2 !important;
}
.home-fill-color {
  color: $homeTextColor !important;
}
.home-fill-color2 {
  color: $homeLightTextColor2 !important;
}
.home-light-color3 {
  color: $homeLightColor3 !important;
}

.home-special-color {
  color: $homeBackgroundColor !important;
}

.android-fill-color {
  fill: white !important;
}
.android-lighttext-color {
  color: $androidTextColor !important;
}
.android-surface-color {
  background: $androidTextColor !important;
}
.android-border-color {
  border: 2px solid $androidLightTextColor !important;
}
.android-background-color {
  background: $androidBackgroundColor !important;
}
.android-light-color {
  color: $androidBackgroundColor !important;
}
.android-text-color {
  color: $androidTextColor !important;
}
.android-fill-color {
  color: $androidTextColor !important;
}
.android-special-color {
  color: $androidBackgroundColor !important;
}


.op-50 {
opacity: 50%;
}
.material-icons-48 {
  font-family: 'Material Icons';
  font-weight: 600 !important;
  font-style: normal;
  font-size: 40px !important;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  padding-right: 0px!important;
  text-transform: none;
  vertical-align: middle;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  padding-bottom: 8px;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: 800 !important;
  font-style: normal;
  font-size: 20px !important;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  padding-right: 0px!important;
  text-transform: none;
  vertical-align: middle;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  padding-bottom: 4px;
}
.button-text {
  vertical-align: middle;
  padding-right: 8px;
}
html {
  box-sizing: border-box;
  height: 100%;
  background-color: black;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body,
input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility !important;
}
::selection {
  background: pink;
}
h1,h2 {
  font-family: "Inter", sans-serif;
  weight: 600 !important;
  font-variation-settings:
    "wdth" 80;
  margin: 0;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
h3,h4,h5,h6,p,a,table th, table td, figure, .m-projTitle, .m-projLink {
  font-family: "Inter Var", sans-serif;
  margin: 0;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: white;
  font-variant: none;
  text-decoration: none;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
}
body.m-locked {
  overflow: hidden;
}
body:before {
  display: block;
  width: 100%;
  height: 100%;
}

h1.m-textSmall,
h1.m-textLarge {
  color: #000;
  font-weight: $fontWeightTyp;
}
h1.m-textLarge {
  letter-spacing: $typLetterSpacing;
}
h1 {
  text-align: left;
  font-weight: $fontWeightTyp;
  line-height: 1.3;
  color: $darkGreyText;
}
h2 {
  font-size: $fontSize1;
  color: $lightGreyText;
  FONT-WEIGHT: 420;
      line-height: $typlineheight;
      // letter-spacing: .25px;
}
h3 {
  font-size: $fontSize1;
  font-weight: $fontWeightTyp;
  line-height: $typlineheight;
}
h4 {
  font-size: $fontSize-1;
  font-weight: $fontWeightTyp;
  letter-spacing: $typLetterSpacing;
  color: $lightGreyText;
}
h2.m-landingText {
  text-align: left;
  letter-spacing: $titleLetterSpacing;
  color: white;
  line-height: .9;
  padding-bottom:32px;
}
h2.m-landingTitle {
  font-weight: $fontWeightTyp;
  text-align: left;
  letter-spacing: $typLetterSpacing;
  color: $lightGreyText;
  line-height: 2;
  letter-spacing: .7px;
  padding-bottom:20px;
}
h5 {
  display: inline-block;
  font-size: $fontSize-1;
  letter-spacing: 1px;
  font-weight: $fontWeightTyp;
  padding-top:32px;
  padding-right:24px;
  text-transform: uppercase;
  color: white;
}
p {
  font-size: $fontSize1;
  letter-spacing: 0;
  color: $darkGreyText;
  line-height: $typlineheight;
}
.description {
  font-size: $fontSize-1;
  padding: 0 0 5px;
  text-align: left;
  color: $darkGreyText;
}
li {
  font-size: $fontSize-1;
  font-weight: $fontWeightTyp;
  line-height: $typlineheight;
  padding: 0;
  margin: 0;
  list-style: none;
  letter-spacing: 0;
}
@media (max-width: 650px) {
  h1.m-quote {
    margin: 0px !important;
    margin-block-end: 0px !important;
  }
  .m-quote.topper {
    margin-top: 100px !important;
  }
  .m-projTitle.titleTest {
    font-size: 36px;
  }
  h1.m-quote, h2.m-landingText {
      font-size: 54px;
      font-weight: 430;
      line-height: 1.2;
    }
  button.m-homebutton  {
  height:48px;
  width: 100%;
}
  button.m-homebutton  {
    border-color: black !important;
  background-color: inherit;
  border-radius: 12px;
  font-family:$font, Arial, sans-serif !important;
  font-weight:500 !important;
  text-align:left;
  padding:0px;
  margin-top:32px;
  cursor: pointer;
  transition: 0.2s;
  color: #BDBDBD;
  }
  button.no-background.back {
    padding: 0px;
  border-color: black !important;
  background:none;
  border-radius: 12px;
  font-family:$font, Arial, sans-serif !important;
  font-weight:500 !important;
  text-align:left;
  cursor: pointer;
  transition: 0.2s;
  color: #BDBDBD;
  }
  .m-landinglist {
    width: calc(100%);
    text-align: center;
    border-radius: 12px;
  }
  .home-padding {
    padding-bottom: 120px;
  }
  .m-projbutton {
    margin-top: 64px !important;
    margin-bottom: 16px !important;
  }
  .m-homebutton {
    margin-top: 0px !important;
    margin-bottom: 12px !important;
    width: 100%;
  }
  h1.lander {
    font-size: $fontSize2;
    font-weight: 475 !important;
  }
  .hidemobile {
    display: none !important;
  }
  h1.pullquote {
      font-size: $fontSize2;
      line-height: $typlineheight;
      margin-top: $mobilepadding;
    margin-bottom: $mobilepadding;
  }
  button.projectDetails  {
  height:148px;
}
  p.table {
  font-size: $fontSizeTyp;
  }
  .bottom-left {
    position: relative;
    padding-top: 16px;
    padding-left: 0px;
  }
  button.projectDetails.no-background  {
    width:100%;
  }
  .m-projTitleGroup {
    margin-top: 0px;
  }
  td, th {
    background: #212121;
    border: 2px solid black;
    color: rgba(255, 255, 255, 0.7);
    font-weight: $fontWeightTyp;
    font-size: $fontSize-1;
    height:64px;
    text-align: center;
    vertical-align: middle;
    border-radius: 12px;
    width: 33%;
  }
  figure.designFigure {
    margin-top: ($mobilepadding/16) !important;
    margin-bottom: ($mobilepadding/4) !important;
  }
  .m-t-400{
  margin-top: 150px !important;
  }
  h4.m-projTitle {
    letter-spacing: $typLetterSpacing;
    font-weight: $fontWeightTyp;
    text-align: left;
    color: $lightGreyText;
    font-size:$fontSizeTyp;
  }
  .m-twoColumnCopy {
    margin: 0 0px 0 0;
  }
  .flex {
  display: flex;
  flex-wrap: wrap;
  }
  .m-twoColumnList {
    flex: 0 1 50%;
  }
  .aboutMe {
    padding-top: 120px;
  }
  .introList {
    margin-top: 32px;
    margin-bottom: 64px;
  }
  h1.m-brandDescription {
    padding-top: 24px;
  }
  .project1.m-b-60mobile {
    margin-bottom: 120px;
  }

  .down-arrow {
    display:none;
  }
  h4.m-projTitle.small, h2.m-projTitleGroup {
      letter-spacing: .5px;
  }
  h4.m-projTitle.small {
	font-size: $fontSizeTyp !important;
	font-weight: $fontWeightTyp;
	text-align: left;
  line-height:1.25;
	color: $lightGreyText;
	margin-bottom: 16px;
}
  .m-b-300 {
    margin-bottom: 0px !important;
  }
  .m-projLink {
        font-size: $fontSize2;
  }
  .m-bt-60 {
    margin-top:24px;
    margin-bottom:24px;
  }
  .m-projTitle.small {
    font-size: $fontSize1;
  }
  h4.m-projTitle.center {
    text-align: left;
  }
  h2.m-landingTitle {
    font-size: $fontSizeTyp;
        FONT-WEIGHT: 420;
  }
  .m-menu-toggle {
    top: 24px;
    right: 32px;
  }
  .p-90-dt {
    padding-bottom: 0px;
  }
  .landingContent {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  h4.center-mobile {
    text-align: center;
  }
  .p-t-200 {
    padding-top: 90px !important;
  }
  .m-t-150-desktop {
    margin-top: 64px !important;
  }
  .m-projTitle.m-b-120-all {
    margin-bottom: 90px;
  }
  .m-projCopy,
  .m-projCopy-top,
  .m-projCopy-bottom {
    font-size: $fontSizeTyp;
  }

  h1.m-textLarge {
    text-align: center;
    font-size: $fontSize2;
  }
  h1.m-textSmall {
    text-align: center;
    font-size:$fontSize1;
  }
  .m-indexText {
    width: auto;
    margin: 130px 24px 64px 24px;
  }
  .project1 {
        margin: 32px;
  }
  .mobile {
    display: initial;
  }
  .l-footerLinks {
    display: none;
  }
  .styleguide {
    margin: 32px;
    width: auto;
  }
  .details {
    margin-left: 0px;
  }
  figure.designFigure {
    font-size: $fontsizemin;
    color:$darkGreyText;
  }
  .l-mobileDropdown {
    padding-top: 120px;
  }
  .m-menu-toggle {
    position: absolute;
    z-index: 100;
    float: right;
  }

  .center {
    text-align: left !important;
  }
  .uxDefinition {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .uxDescription {
    font-size: $fontSize2;
    font-weight: $fontWeightTyp;
  }
  .descriptionTitle {
    margin-top: 80px;
  }
  figure {
    margin-bottom: 80px;
    margin-top: 0;
  }
  .m-quote {
    margin-bottom: $projpadding;
  }
  .m-quote.no-top {
    margin-bottom: 32px;
    margin-top: 0px;
  }
  .boundingColor {
    min-height: auto;
    margin-bottom: 24px;
  }
  .webDiagram {
    width: 80%;
    margin: 0 auto $projpadding auto;
  }
  .m-webPhoto {
    margin-left: 24px;
    margin-right: 24px;
  }
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: auto;
    flex-wrap: nowrap;
  }
  h1 {
    font-size: $fontSize5;
  }
  h2 {
    font-size: $fontSizeTyp;
  }
  .aboutCopy {
    padding: 0 0 32px;
    font-size: $fontSize1;
  }
  footer a {
    line-height: 3;
    align-self: flex-end;
  }
  footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
  .projectDetails {
    margin-bottom: 0px;
  }

  .l-w2 {
  margin-left: $marginHome;
  margin-right: $marginHome;
}
  .l-w1 {
    margin-left: $marginProj;
    margin-right: $marginProj;
  }
  .project1,
  .project2 {
    margin-bottom: 140px;
  }
  .m-brand {
    left: $marginHome;
    margin-top: 24px !important;
    color: $darkGreyText;
  }
  .m-back {
    left: $marginHome;
  }
  .m-brandEnd {
    margin-left: auto;
    margin-right: auto;
    order: 1;
  }
  .tasks {
    display: none;
  }
}
@media (min-width: 651px) {
  .m-quote.topper {
    margin-top:210px !important;
  }
  .m-projTitle.titleTest {
    font-size: 48px;
  }
  h2.m-landingText {
  FONT-WEIGHT: 420;
}
  button.m-homebutton  {
  height:86px;
  width: 100%;
}
  button.m-homebutton  {
    border-color: black !important;
  border-radius: 12px;
  font-family:$font2, Arial, sans-serif !important;
  font-weight:500 !important;
  text-align:left;
  padding:0px;
  margin-top:32px;
  cursor: pointer;
  transition: 0.2s;
  color: $homeLightTextColor;
  background:none;
  }
  .m-landinglist {
    text-align: center;
    border-radius: 12px;
      padding:6px;
  }
  .home-padding {
    padding-bottom: 90px;
  }
  .m-projbutton {
    margin-top: 64px !important;
    margin-bottom: 64px !important;
  }
  .m-homebutton {
    margin-top: 0px !important;
  }
  h1.lander {
    font-size: $fontSize2;
    font-weight: 475 !important;
  }
  .hidemobile {
    display: initial;
  }
  h1.pullquote {
      font-size: $fontSize3;
      line-height: $typlineheight;
    }
  p {
    font-weight: $fontWeightTyp;
  }
  button.projectDetails {
  height:86px;
}
  p.table {
  font-size: inherit;
  }
  .bottom-left {
    position: relative;
    padding-top: 16px;
    padding-left: 16px;
  }
  .l-w0.m-mobileProjLink {
    width: auto !important;
  }
  button.projectDetails.no-background {
    width:320px;
  }
  button.no-background.back {
    padding: 0px;
  background:none;
  border-radius: 12px;
  font-family:$font, Arial, sans-serif !important;
  font-weight:500 !important;
  text-align:left;
  cursor: pointer;
  transition: 0.2s;
  }
  .abcd {
      margin-bottom: 32px;
    }

  h1.m-quote.starter {
    margin-top: 0px;
  }

  td, th {
    background: #212121;
    border: 2px solid black;
    color: rgba(255, 255, 255, 0.7);
    font-weight: $fontWeightTyp;
    font-size: $fontSize1;
    height:80px;
    text-align: center;
    vertical-align: middle;
    border-radius: 12px;
    width: 33%;
  }
  figure.designFigure {
    margin-top: 8px !important;
    margin-bottom:32px !important;
  }

  .m-twoColumnCopy {
    margin: 0 80px 0 0;
  }
  .flex {
  display: flex;
   }
  .aboutMe {
    padding-top: 120px;
  }
  .introList {
    margin-top: 32px;
  }
  .project1.m-b-60mobile {
    margin-bottom: 0px;
  }

  .down-arrow {
    padding-bottom: 16px;
    width: 40px !important;
  }
  h4.m-projTitle {
    letter-spacing: $typLetterSpacing;
    font-weight: $fontWeightTyp;
    text-align: left;
    letter-spacing: .2px;
    color: $lightGreyText;
  }
  .m-b-300 {
    margin-bottom: 180px !important;
  }
  .m-projLink {
    font-size: $fontSize2;
  }
  .m-projTitle.small {
    font-size: $fontSize1;
  }
  .landingContent {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .p-t-200 {
    padding-top: 200px !important;
  }
  .m-t-150-desktop {
    margin-top: 150px !important;
  }
  .m-projTitle.m-b-120-all {
    margin-bottom: 120px;
  }
  .m-projCopy,
  .m-projCopy-bottom {
    font-size: $fontSize1;
  }
  .m-projCopy-top {
  font-size: 30px;
}
  h1.m-textLarge {
    font-size: $fontSize4;
    padding-bottom: 16px;
  }
  h1.m-textSmall {
    font-size: $fontSize1;
  }
  .mobile {
    display: none;
  }
  .l-footerLinks {
    display: block;
    width: 55%;
  }
  h4.m-projTitle {
    font-size: $fontSize2;
  }
  .details {
    margin-left: 0px;
  }
  figure.designFigure {
    font-size: $fontSize-1;
    color:$darkGreyText;
  }
  .l-mobileDropdown {
    padding-top: 164px;
  }
  .m-menu-toggle {
    position: absolute;
    z-index: 100;
    float: right;
  }

  .uxDefinition {
    margin-top: 120px;
    margin-bottom: 90px;
  }
  .uxDescription {
    font-size: $fontSize4;
    font-weight: $fontWeightTyp;
  }
  li.inline {
    display: inline-block;
    margin-right: 16px;
  }
  .descriptionTitle {
    margin-top: 164px;
  }
  figure {
    margin-bottom: 164px;
    margin-top: 16px;
  }
  .m-quote {
    margin-top: 80px;
  }
  .m-quote.no-top {
    margin-top: 0px;
  }
  .boundingColor {
    margin-bottom: 16px;
  }
  .webDiagram {
    width: 55%;
    margin: 0px auto 64px;
  }
  .webDiagram.chart {
    margin: 0 auto 64px;
  }
  .m-webPhoto {
    width: 75%;
    margin: 64px auto 64px;
  }
  .flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
  .aboutCopy {
    padding: 0 0 32px;
    font-size: $fontSize1;
  }
  footer {
    padding-bottom: 32px;
  }
  .footer li {
    display: inline-block;
    padding: 0 16px;
    font-size: $fontSize1;
  }
  .footer li:last-child {
    padding: 0;
  }
  .footer li a {
    align-self: flex-end;
  }
  footer {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .l-w1,
  .l-w2,
  footer {
    margin-right: auto;
    margin-left: auto;
  }
  figure {
    margin-top: 0;
    margin-bottom: 80px;
  }
  h3 {
    transition: all 0.15s ease-in-out;
  }
  h4 {
    font-size: $fontSize1;
    line-height: 32px;
    margin-bottom: 16px;
  }
  .project1,
  .project2 {
    margin: 0 64px;
  }
  .margin10 {
    margin-top: -16px;
  }
  .tasks {
    display: inherit;
  }
}
@media (min-width: 651px) and (max-width: 850px) {
  h1.m-quote, h2.m-landingText {
      font-size: 148px;
    }
  .w-600 {
    width:100%;
  }
  .m-t-400{
  margin-top: 200px !important;
  }
  .m-projTitleGroup {
    margin-top: 24px;
  }
  .m-twoColumnList {
    flex: 0 1 50%;
  }
  h2.m-landingTitle {
    font-size: $fontSizeTyp;
  }
  .m-menu-toggle {
    top: 24px;
    right: 32px;
  }

  .m-indexText {
    width: 560px;
    margin: 180px auto 90px auto;
  }
  .l-flexbox-grey {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .styleguide {
    margin: 64px;
    width: auto;
  }
  .flexContainer {
    margin-bottom: 140px;
  }
  h1 {
    font-size: $fontSize5;
  }
    .m-back {
      left: 32px;
    }
    .m-back {
    left: 32px;
    }
  .m-brand {
    left: 32px;
    margin-top: 24px !important;
  }
  #l-fixedDesktopMenu {
    top: -4px;
    right: 16px;
  }
  .m-brandEnd {
    margin-left: 32px;
    align-self: flex-start;
  }
  .img2 {
    width: auto;
  }
  .project1,
  .project2 {
    align-self: center;
  }
  .l-w1,
  .l-w2 {
    width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }
  .l-w0 {
    width: 600px;
  }
  .l-flexbox,
  .l-flexbox-m0 {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 850px) {
  .weighty {
  font-weight: 500;
  font-size: 24px;
}
  #fixedMenu {
		margin-top: 8px;
	}
	.header-left {
		position: absolute;
		left: 16px;
	}
	.header-right {
		position: absolute;
		right: 16px;
	}
  .l-flexbox-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    FONT-WEIGHT: 420;
  }
  .m-bt-60 {
    margin-top: $mobilepadding  !important;
  }


h1.pullquote {
  margin-top: $mobilepadding !important;
  margin-bottom: $mobilepadding !important;
    FONT-WEIGHT: 380;
}

  .m-b-60, .pad-Bot-60, .pad-Bot-60-med, .m-quote.no-top, .m-bt-60  {
    margin-bottom: $mobilepadding !important;
  }
  .m-projTitleGroup,   h4.m-projTitle.center, h4.m-projTitle.center.second {
margin-bottom: ($projpadding/8) !important;
  }
  .introList {
    margin-bottom: 64px;
  }
  h1.m-quote {
    FONT-WEIGHT: 500;
  }
  li .abcd {
    font-size: $fontsizemin;
  }
  h2.m-landingText.lower {
  display: block;
    text-align: left;
    color: $lightGreyText;
    font-family: $font2;
    letter-spacing: $typLetterSpacing;
    font-size:$fontSize1;
    font-weight: $fontWeightTyp;
    line-height: $typlineheight;
  }
  h2.m-landingText.lower.light {
    color: #9e9e9e;
    margin-bottom:120px;
  }
  h1.m-brandDescription {
    font-size: $fontSize1;
  }
  .intro {
  margin-bottom:60px;
  }
  h1.m-brandDescription {
    font-weight: $fontWeightTyp;
  }
  .rounded {
  border: 0px;
  border-radius: 0px;
  padding: 0px;
  }
  h2.projectDetails {
    font-size: $fontSize-1;
      padding-bottom: 0px;
      FONT-WEIGHT: 420;
  }
  h2.projectDetails, h2 {
      color: $darkGreyText;
    }
  .order1 {
    width: 100%;
    order:2;
  }
  .order2 {
    order:1;
  }
  button.projectDetails.hidemobile {
    opacity: 0;
    height:1px;
    margin:0;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: initial;
  }
}
@media (min-width: 851px) {
    .weighty {
  font-weight: 500;
  font-size: 36px;
}
  #fixedMenu {
		margin-top: 8px;
	}
	.header-left {
		position: absolute; 
		left: 16px;
	}
	.header-right {
		position: absolute;
		right: 16px;
	}
  .l-flexbox-home {
   display: flex;
   flex-direction: row;
   justify-content: center;
 }
  .m-bt-60 {
    margin-top: $projpadding  !important;
  }
  h1.pullquote {
    margin-top: $projpadding !important;
    margin-bottom: $projpadding !important;
    FONT-WEIGHT: 500;
  }
.process_bottom {
  margin-bottom: ($projpadding/1.5) !important;
}
  .m-b-60,  .l-flexbox-m0, .pad-Bot-60,  .pad-Bot-60-med, .m-quote.no-top, .m-bt-60 {
    margin-bottom: $projpadding !important;
  }
h1.m-quote,  .m-quote.starter.m-t-20 {
  margin-bottom: 60px !important;
}
  .m-projTitleGroup,   h4.m-projTitle.center, h4.m-projTitle.center.second {
margin-bottom: $calclineheight !important;
  }
  .introList {
    margin-bottom: 80px !important;
  }
  h1.m-quote {
    font-weight: 500;
  }
  h2.m-projTitleGroup {
    font-size: $fontSize1;
  }
  em {
      font-weight:$fontWeightReduced;
      font-style: normal;
      color: $lightGreyText;
  }
  .mega-bottom {
    padding-bottom: 64px !important;
  }
  .m-t-400{
  margin-top: 200px !important;
  }
  .m-projTitleGroup {
    margin-top: 0px;
  }
  h2.m-landingTitle {
    font-size: $fontSize1;
  }
  .img2 img {
    border-radius: 12px;
  }
  h1.m-brandDescription {
    font-size: $fontSize2;
  }
  .intro {
  margin-bottom:60px;
  }
  .hidedesktop {
    opacity: 0;
    height:0px;
    margin:0;
  }
  .rounded {
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  padding: 32px;
 box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  .vert-center h1 {
    line-height: $typlineheight;
    font-size: $fontSize1;
  }
    h2.projectDetails {
      font-size: $fontSize2;
      text-align:left;
        margin-top:0px;
          padding-bottom: 0px;
          font-weight: 275;
          color: $darkGreyText;
          text-shadow: 1px 1px 2px #424242;
      }

    .l-2cl {
      columns: 2;
      column-gap:32px;
    }
    .details {
      margin-top: 32px;
    }
    .l-flexbox-grey {
      display: flex;
      flex-direction: row;
    }
  .order1 {
    width: 480px;
    padding-right:64px;
    order:1;
  }
  .order2 {
    order:2;
  }
  .projectList {
    margin-top:48px;
    margin-bottom:48px;
    display: inline-block;
    //border-bottom: 2px solid white;
    padding-bottom: 0px;

  }
  h1.lander.wavy {
    text-align:center;
line-height: 70px;
  }
  .m-twoColumnList {
    flex: 0 1 25%;
  }
  .desktop {
    display: initial;
  }
  .mobile {
    display: none;
  }
  #l-fixedDesktopMenu {
    top: -4px;
    right: 16px;
  }
  h2.m-landingText {
    font-size: 140px;
  }
}
@media (max-width: 1099px) {
  #fixedMenu {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  li.abcd {
    padding: 6px 6px  6px 6px !important;
  }
  #l-fixedDesktopMenu {
    top: -4px;
    right: 30px;
  }

  .m-twoColumnImageA {
    flex: 100%;
    margin: 10px 0px 10px 0px;
  }
  .m-twoColumnImageB {
    flex: 100%;
    margin: 0px;
  }
  .m-webMockup {
    margin: 0 24px;
  }
  .m-prepContent {
    margin: 0px 16px;
  }
  .m-flowContent {
    margin: 30px 16px;
  }
}
@media (min-width: 1100px) {
  #fixedMenu {
    position: absolute;
    width: 100%;
    z-index: 100;
  }
  .w-600 {
    width: 550px;
  }
  h1.m-quote, h2.m-landingText {
      font-size: 130px;
      font-weight: 500;
      line-height: 1.05;
    }

  #l-fixedDesktopMenu {
    top: -4px;
    right: 30px;
}
  .styleguide {
    margin: 0px 120px 0px 120px;
    width: auto;
  }
  .m-webMockup {
    margin: 0px 64px 0px 64px;
  }
  .solo.m-webMockup {
    margin: 0px 64px 64px 64px;
  }
  .m-prepContent {
    margin: 0px 300px;
  }
  .m-flowContent {
    margin: 64px 300px;
  }
  .m-twoColumnImageA, .m-twoColumnCopyA {
    flex: 50%;
    margin-right: 32px;
  }
  .m-twoColumnImageB, .m-twoColumnCopyB {
    flex: 50%;
    margin-left: 32px;
  }
  .l-flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .l-flexbox-m0 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}
@media (min-width: 851px) and (max-width: 1100px) {
  .w-600 {
    width: calc(280px + 5vw);
  }
  h1.m-quote, h2.m-landingText {
      font-size: 148px;
    }
  .m-indexText {
    width: 828px;
    margin: 180px auto 64px auto;
  }
  .l-flexbox-grey {
    width: 700px;
    margin: auto;
  }
  .styleguide {
    margin: 64px;
    width: auto;
  }
  .flexContainer {
    margin-bottom: 100px;
  }
  h1 {
    font-size: $fontSize4;
  }
  .project1,
  .project2 {
    margin: 0 32px;
    align-self: center;
  }
  figure {
    font-family: $font, monospace;
    font-weight: $fontWeightTyp;
    line-height: $typlineheight;
    color:$lightGreyText;
  }
  .img2 {
    width: 380px;
  }
  .m-back {
  left: 64px;
}
  .m-brand {
    left: 32px;
    margin-top: 24px !important;
  }
  .m-brandEnd {
    margin-left: 32px;
    align-self: flex-start;
  }
  .l-w1, .l-w2 {
    width: 820px;
  }
  .l-w0 {
    width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  li.abcd {
    padding: 12px 12px 12px 12px !important;
  }
  .m-indexText {
    width: 980px;
    margin: 180px auto 100px auto;
  }
  .l-flexbox-grey {
    width: 840px;
    margin: auto;
  }
  .project1,
  .project2 {
    margin: 0 90px;
    align-self: center;
  }
  .m-twoColumnCopy {
    flex: 50% 1;
  }

  .flexContainer {
    margin-bottom: 220px;
  }
  .m-back {
    left: 64px !important;
  }
  .m-brand {
    left: 32px !important;
    margin-top: 24px  !important;
  }
  .m-brandEnd {
    margin-left: $navLarge;
    align-self: flex-start;
  }
  .img2 {
    width: 400px;
  }
  .sketchImage {
    width: 700px;
  }
  .l-w2,
  .l-w0 {
    width: 1060px;
  }
  .l-w1 {
    width: 1000px;
  }
}
@media (min-width: 1600px) {

  .l-w2 {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-w1 {
    width: 1020px;
    margin-left: auto;
    margin-right: auto;
  }
  .m-indexText {
    width: 1100px;
    margin: 180px auto 100px auto;
  }
  .project1,
  .project2 {
    margin: 0 64px;
    align-self: center;
  }
  .m-twoColumnCopy {
    flex: 50% 1;
  }
  .flexContainer {
    margin-bottom: 200px;
  }
  .l-w0 {
    width: 900px;
  }
  .m-back {
    left: 70px !important;
  }

  .m-brand {
    left: 32px;
    margin-top: 24px !important;
  }
  .m-brandEnd {
    margin-left: $mobilepadding;
    align-self: flex-start;
  }
  .img2 {
    width: 800px;
  }
  .sketchImage {
    width: 800px;
  }
}

@media (max-width: 2599px) {
  .mega {
    margin-top: 150px;
  }
}
@media (min-width: 2600px) {
  .mega-top {
    margin-top: 500px;
  }

  .mega-top.l-w1 {
    width: 1200px !important;
  }
  h1.m-quote, h1.pullquote {
    max-width: 1500px !important;
  }
  .m-webMockup {
    margin: 10px 64px 24px 64px;
  }
  h2.m-landingText {
  font-size: 110px;
  font-weight: 120;
}
  .m-brandDescription {
    margin-top:32px !important;
  }
  h4.m-projTitle {
    font-size: $fontSize2;
  }
  .l-w1, .l-w2 {
    width: 2000px;
  }
  .img2 {
    width: 900px;
  }
  .project1 {
    margin: 0 120px;
  }
h2.m-landingText {
    font-size: $fontSize6;
}
.m-twoColumnCopy {
width: 1000px;
}
  }
.l-mobileDropdown ul {
  width: 100%;
}
.l-mobileDropdown a {
  display: block;
  width: 100%;
  padding-top: 16px;
  text-align: left;
  font-family: $font;
  font-size: $fontSize3;
  line-height: 64px;
  font-weight: $fontWeightTyp;
}
.l-mobileDropdown a:hover {
  color: $lightGreyText;
}
.m-brand {
  position: absolute;
  z-index: 100;
}
.m-back {
  position: fixed;

  z-index: 100;
}
.back-top {
  top: 100px;
}
.resume a {
  color: $darkGreyText;
}
h2.projectDetails {
  font-family: $font2, sans-serif;
  letter-spacing: $typLetterSpacing;
  line-height: $typlineheight;
}
.projectName {
  font-size: $fontsizemin;
  font-weight: $fontWeightTyp;
  line-height: $typlineheight;
  letter-spacing: 1px;
  margin-top: 0;
  padding: 5px 0px 5px 0;
  color: $darkGreyText;
  text-transform: uppercase;
}
.projectSpace {
  padding: 0 8px 15px;
}
.img2 {
  margin: 0;
  padding: 0;
}
.img2 img {
  width: 100%;
}
header {
  position: absolute;
  z-index: 40;
  top: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: none;
}
.padPortrait {
  padding-top: 116px;
}
h3 {
  text-align: left;
}
li .bold {
  font-weight: $fontWeightTyp;
  font-size: $fontSize2;
  color: $lightGreyText;
}
#l-fixedMenu.m-fullHeight {
  width: 100% !important;
}
footer li a {
  color: $lightGreyText;
  font-family: $font;
  text-decoration: none;
}
h2.m-projTitleGroup {
  font-weight: $fontWeightTyp;
  text-align: left;
  color: $thirdColorText;
  margin-bottom: 8px;
  margin-top: 64px;
}

.m-projTitleGroupDO {
  margin-bottom:32px;
  margin-top: 0px;
}
h1.m-brandDescription {
  display: block;
  text-align: left;
  color: $lightGreyText;
  font-family: $font2, sans-serif;
  letter-spacing: $typLetterSpacing;
  line-height: 1.7em;
}
.m-border {
  border: 1px solid #d1d1d1;
}
.l-iconGroup {
  margin-top: 80px;
}
.square-legend {
  margin-bottom: -3px;
  width: 25px;
  height:24px;
  display: inline-block;
}
.legend {
  column-gap: 16px;
  padding-top: 80px;
  padding-bottom: 200px;
}
.legend li {
  line-height: 2.2;
  list-style-type: none;
  float: left;
  width: 50%;
}
.legend p {
  padding-bottom: 16px;
  font-size: $fontSize1;
}
.l-iconGroup {
  bottom: 64px;
  position: fixed;
  display: none;
}
.portrait {
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.boundingColor {
  padding-top: 120px;
}
.m-projCategories h5 {
  padding: 0px 10px 0px 0;
}
h1.m-blackText,
h5.m-blackText, a.m-blackText, h2.m-blackText,
a.darkText {
  color: $lightGreyText;
  cursor: pointer;
}

h2.greyText {
  color: $lightGreyText;
}
.descriptionTitle {
  text-transform: uppercase;
  font-size: $fontSize1;
}
figure {
  font-size: $fontSize1;
}
h1.m-quote, h1.pullquote {
  max-width: 1000px;
  text-align: left;
  color: $darkGreyText;
}
h1.m-quote {
  font-family: $font;
}
h1.pullquote {
  font-family: "Inter Var";
}
.m-quote:empty,
figure:empty,
.descriptionTitle:empty {
  margin-top: 0;
  margin-bottom: 0;
}
h2.name {
  text-align: center;
  font-size: $fontSize-1;
  font-family: $font2, monospace;
  font-weight: $fontWeightTyp;
  letter-spacing: 1px;
  line-height:24px;
  text-transform: uppercase;
}
.center {
  text-align: center;
}
.nameInfo {
  margin-top: 64px;
}
footer {
  width: 100%;
}
li.inline {
  display: inline-block;
  margin-right: 16px;
  padding: 0;
}
.m-projLink:hover,
.m-projLink2:hover {
  color: #cccccc;
}
li {
  font-family: $font;
}
.square-legend {
  margin-right: 16px;
}
ul.projCopy {
  line-height: 1.65;
}
.pink {
  color: $lightGreyText !important;
  text-transform: uppercase;
  font-weight: $fontWeightTyp;
  display: inline-block !important;
  padding-right: 32px;
}

.uxHeader {
  font-size: $fontSize6;
  text-align: center;
}
.uxLink {
  width: 350px;
  padding: 30px 0;
  margin: 0 auto 80px;
  letter-spacing: $typLetterSpacing;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  transition: 0.3s ease-in-out;
}
.uxLink:hover {
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.14), 0 2px 6px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.all-Center {
  text-align: center;
}
.fontUp {
  font-size: $fontSize1;
  text-decoration: underline;
}

a.darkText {
  padding-bottom:32px;
}
hr {
  margin: 32px;
  border-top: 0.2px solid #e0e0e0;
}

.l-footerLinks {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.m-mobileProjLink {
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
}
ul.footer {
  margin-top: 16px;
  padding-left: 0px;
  margin-left: auto;
  margin-right: auto;
}
h5.m-blackText {
  color: $darkGreyText;
}

.uxDiagram {
  height: 50px;
  width: 100px;
  padding-bottom: 16px;
}
.p-90,
.p-90-dt {
  padding-bottom: 90px;
}
.p-top-60 {
  padding-top: $projpadding;
}
@media (max-width: 850px) {
  .process_bottom {
    margin-bottom: calc($projpadding / 4) !important;
  }
  h2.m-projTitleGroup {
    font-size: $fontSizeTyp;
  }
  em {
      font-weight:$fontWeightReduced;
      font-style: normal;

  }

  .mega-bottom {
    padding-bottom: 5px;
  }
  .vert-center h1 {
    line-height: $typlineheight;
    font-size: $fontsizemin;
  }
  .projectList {
    margin-top:20px;
    margin-bottom:20px;
    display: inline-block;
    //border-bottom: 1.5px solid white;
    padding-bottom: 0px;
  }
  h1.lander.wavy {
    text-align:center;
     vertical-align: middle;
  }
  .details {
    margin-top: 16px;
  }
  .m-twoColumnCopy {
    width: auto;
  }
  .l-flexbox-grey {
    flex-direction: column;
  }
}

.m-projLink2 {
  font-size:$fontSize2;
}
.m-t-0,
.m-quote.m-t-0,
.m-projTitleGroup.m-t-0 {
  margin-top: 0px;
}
.m-quote.m-t-0 {
  margin-top: 0px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animated {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  -webkit-animation-duration: .35s;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
.animated.delay {
  animation-delay: 0.2s;
}
.animated.delay2 {
  animation-delay: 0.2s;
}
.animated.delay3 {
  animation-delay: 0s;
}

.m-t-150 {
  margin-top: 150px;
}
.m-t-30 {
  margin-top: 32px;
}
.m-projCopy,
.m-projCopy-top,
.m-projCopy-bottom,
.m-projCopy-inline {
  color: $darkGreyText;
  line-height: $typlineheight;
}
.m-projCopy-top {
  padding: 0 0 0 0;
}
.m-projCopy-bottom {
  padding: 0 0 100px 0;
}

.uxLink {
  font-size: $fontSize1;
}
.visible {
  display: initial;
}
.m-15 {
  margin-right: 15px;
}
.m-b-10 {
  margin-bottom: 16px;
}
.m-b-20 {
  margin-bottom: 16px;
}
.p-inline {
  display: inline;
}
.l-grey {
  color:$lightGreyText;
  margin-left: 16px;
}

a.h2 {
  color: pink;
  font-weight: $fontWeightTyp;
  font-size:$fontSize2;
  font-family: $font, monospace;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-b-120 {
  margin-bottom: 120px;
}
.m-t-150 {
  margin-top: 150px !important;
}
.m-t-90 {
  margin-top: 90px;
}
.m-t-20 {
  margin-top: 16px;
}
.m-t-60 {
  margin-top: $projpadding;
}
.m-t-180{
  margin-top:150px;
}
.ft-20 {
  font-size:$fontSize2;
}
.l-flexbox-alldevs {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
}
.t-center-all {
  font-size: $fontSize-1;
      font-weight: $fontWeightTyp;
      text-align: left;
      letter-spacing: 1px;
      color: $lightGreyText;
      margin-bottom: 4px;
      text-align:center;
  }

.m-mobileProjLink {
  display: block;
}
.project1:hover h3,
.project1:hover a {
  color: $lightGreyText;
  transition: 0.3s;
}
.details {
  text-align: center;
}
h3.projectName {
  text-align: center;
}
.caps {
  text-transform: uppercase;
}
h4.m-projTitle.landing {
  padding-top: 0px;
}

.process_content {
  background-color: white;
  border: 1px solid #dcdcdc;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.dotSpace {
  padding-left: 16px;
  padding-right: 16px;
}
.grey {
  color:$lightGreyText;
  padding-left: 16px;
}
.p-l-10 {
  padding-left: 16px;
}

.m-projTitleGroup.m-t-0.p-l-10 {
  color: $lightGreyText;
}
.m-projTitleGroup.m-t-0.p-l-10:hover {
  color: $lightGreyText;
}
.inlineProj {
  font-family: $font;
  font-weight: $fontWeightTyp;
  font-size: $fontSizeTyp;
  color: #ff00ff;
  text-transform: uppercase;
  margin-top:32px;
}
.p-t-20 {
  padding-top:24px !important;
}

ul {
  padding-left: 0px !important;
}
.media {
  width: 200px;
}

// desktop navbar

#l-fixedDesktopMenu {
  position: absolute;
  z-index: 100;
  float: right;
  color: $lightGreyText;

  @media (max-width: 769px) {
    display: none;
  }
}
.l-desktopMenuBar {
  display: flex;
  justify-content: space-between;

  & li {
    justify-self: end;
    list-style-type: none;
    margin: auto 0;
  }

  & a {
        font-weight: $fontWeightTyp;
        color: #9e9e9e;
            margin: 0 .7rem;
        text-decoration: none;
        cursor: pointer;
        font-size: $fontSizeTyp;

    &:hover {
      color: $lightGreyText;
    }
  }
}

.m-landingText.light {
  font-weight:400;
  margin-top: -8px;
  margin-bottom: 32px;
}

h4.m-projTitle.process {
  padding-top: 150px;
  padding-bottom: 32px;
}

.m-projLink {
  color: $lightGreyText;
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 16px;
  display: inline-block;
  text-align: center;
  font-weight: $fontWeightTyp;
}

h2.m-landingText {
color: white;
margin-bottom: 32px;
transition: font-weight .3s ease-out;
}
.feature_set {
  display: flex;
  flex-direction: row;
}

.vert-center {
flex: 1 0 0;
background-color: #616161;
margin: 5px;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04), 0 3px 6px rgba(0, 0, 0, 0.08);
display: table;
vertical-align: middle;
width: inherit;
border-radius: 32px;
}

.vert-center h1 {
  height: 80px;
  text-align: center;
  color: white;
  font-weight: $fontWeightTyp;
  letter-spacing: $typLetterSpacing;
  display:table-cell;
  vertical-align: middle;
  text-align:center;
}
img {
  opacity: 1;
  border-radius: 12px;
}
.m-projCopy-inline {
  font-size: $fontSize1;
}
.blackline {
  border-top: 1px solid $lightGreyText;
  border-bottom: 1px solid $lightGreyText;
  margin-top: 64px;
}
.m-twoColumnList {
  padding-top:25px;
  padding-bottom:15px;
}
h2.m-projList {
font-size: $fontSize-1;
    font-weight: $fontWeightTyp;
    text-align: left;
    color: $lightGreyText;
    margin-bottom: 4px;
      line-height: $typlineheight;
  }
  button.projectDetails.no-background  {
    margin-top:0px;
  }
  button.projectDetails {
    border-color: black !important;
  background-color: #212121;
  border-radius: 12px;
  font-family:$font, Arial, sans-serif !important;
  font-weight:500 !important;
  text-align:center;
  padding:0px;
  margin-top:32px;
  cursor: pointer;
  transition: 0.2s;
  color: #BDBDBD;
  }
  button.projectDetails.no-background  {
  background-color: initial;
  color: white;
  }
button.m-blackText {
padding: 6px 18px;
border-radius: 12px;
font-family:$font;
font-weight:600 !important;
text-align:center;
margin-top:0px;
cursor: pointer;
transition: 0.2s;
color: #BDBDBD;
font-size:$fontSize-1;
}
button.header-buttons {
padding: 6px 6px;
border-radius: 8px;
font-family:$font;
font-weight:600 !important;
text-align:center;
margin-top:0px;
cursor: pointer;
transition: 0.2s;
font-size:$fontSize-1;
}
 button.m-homebutton {
  font-weight:600;
  letter-spacing: $typLetterSpacing;
  font-size:$fontSize4;
}
button.projectDetails{
  font-weight:600;
  letter-spacing: $typLetterSpacing;
  font-size:$fontSize2;
}
h2.m-landingText.p-b-0 {
  padding-bottom:0px;
}
h4.hidedesktop {
  font-size: $fontSize-1;
  font-weight: $fontWeightTyp;
    line-height: $typlineheight;
    letter-spacing: $typLetterSpacing;
    margin-top: 0;
    padding: 5px 0;
    color: #9e9e9e;
}

h1.m-brandDescription {
  font-weight: $fontWeightTyp;
}

.m-projTitle.p-t-0 {
  padding-top: 0px;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
}
table tr th,
table tr td {
  padding: 5px;
  transition: 0.1s;
  transition-timing-function: ease-in-out;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 12px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 12px;
}

table th:hover, table td:hover{
  border-radius: 48px;

}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius:12px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}
.big {
  opacity: .5;
  padding-left:16px;
}
h2.m-landingTitle.upper {
  text-transform: uppercase;
  letter-spacing: .07em;
}
.order1 h2 {
  text-shadow: 1px 1px 2px #424242;
}

td > p, th > p {
  font-weight: $fontWeightTyp;
  line-height: $typlineheight;
}


li.abcd {
  font-family: "Inter";
  margin-right: 8px;
  padding: 12px;
  border-radius: 20px;
  font-weight:600;
  text-align: left;
  margin-bottom: 4px;
  // text-transform: Uppercase;
  display: inline;
    font-size: 14px;
    // letter-spacing: .7px;
  }
button {
  border-width: 0px !important;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.tree {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
}

.tree span {
  background: $tree-bg-color;
  border: 1px solid $tree-border-color;
  color: $tree-text-color;
  border-radius: 12px;
  padding: 12px 20px;
  display: flex;
  margin: 20px;
  align-items: center;
  text-align: center;
  font-size: 12px;
  position: relative;
  justify-content: center;
  line-height: $typlineheight;
}

.p, .np {
  display: flex;
  align-content: center;
}

.tree .span:before {
  content: "";
  width: 16px;
  height: 1px;
  content: "";
  background-color: grey;
  position: absolute;
  left: -34px - 1px;
  top: 0;
  bottom: 0;
  margin: auto;
  .first > .p > & { display: none; }
}

.inherit {
  max-width: 100%;
display: flex;
min-height: 207px;
}
.principles {
  padding: 32px;
  text-align: left !important;
}

.principles-padding {
  margin-bottom: 8px;
}
.principle-50-l {
  flex: 50% 1;
  margin-right:4px;
}

.principle-50-r {
  flex: 50% 1;
  margin-left:4px;
}
.principle-50home-l {
  flex: 50% 1;
  margin-right:16px;
}
.principle-50home-r {
  flex: 50% 1;
  margin-left:16px;
}

.color-fill {
  padding: 16px;
  width: 110px;
  margin-bottom: 16px;
  border-radius: 12px;
  background-color: $gatorade2Color !important;
}

.table {
  padding-top:20px;
  padding-bottom:20px;
}

.spaced {
  letter-spacing: 2px;
  font-weight: 500;
}
.p-Bot-120 {
  padding-bottom: 120px !important;
}

h1.inline {
  display: inline-block;
  font-size: 22px;
}

.all {
  margin-right: -8px;
}

.hide {
  display: none;
}
.intro-list {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  }
.icon-right {
  padding-left: 8px;
}
.no-background {
  background: none;
}
.p-4-t {
    padding-top: 4px;
}
.p-bottom {
position: sticky !important;
bottom: 0px !important;
}
.r-brand{
left: 32px !important;
margin-top: 24px  !important;
}

a {
  height: o !important;
}

h1.m-quote {
    line-height: .9;
  }

  .m-projTitle.titleTest {
    padding-top:48px;
    padding-bottom:32px;
    line-height: 1.5;
  }

  .p-t-icon {
  padding-top:28px;
  }



  .difference {
  mix-blend-mode: difference;
}


h2.home_topper {
  padding-top:210px !important;
}

#cursor {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: blue;
  position: absolute;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

#password {
  width: 400px;
  height: 100px;
  border-radius: 16px;
  font-size: 50px;
  font-family:  "Inter", sans-serif;
  background: none;
  outline: 5px solid $androidTextColor;
}

#password:focus {
  outline: 5px solid black;
  background: none;
}

  .passwordcontainer {
    width: 500px;
    height: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  input {
padding: 0px !important;
padding-inline: 30px !important;
color: none !important;
border: none !important;
  }

  ::placeholder {
    color: $androidTextColor;
    opacity: 1; /* Firefox */
  }

  button.projectDetails:hover, .abcd:hover {
    outline: 3px solid black;
  }


  .alert {
    padding: 20px;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 15px;
    border-radius: 16px;
  }
  
  /* The close button */
  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  button.passcode {
    border-color: black !important;
  background-color: $androidTextColor;
  border-radius: 12px;
  text-align:center;
  padding:0px;
  margin-top:32px;
  cursor: pointer;
  transition: 0.2s;
  height:80px;
  width:400px;
  font-family:$font, Arial, sans-serif !important;
  font-weight:600;
  letter-spacing: $typLetterSpacing;
  font-size:$fontSize2;
  color: white !important;
  }

  .addedContainer {
    background-color: $androidTextColor;
    border-radius:12px!important;
    box-shadow:none !important;
    color: white;
    padding:5px 5px 5px 30px;
    width: 400px;
  }